import React from 'react';
import ErrorPage from '../components/General/ErrorPage';
import Header from '../components/Header/Header';
import withApollo from '../lib/withApollo';

const NotFoundPage = () => {
  return (
    <>
      <Header active="page" />
      <ErrorPage statusCode={404} />
    </>
  );
};

export default withApollo(NotFoundPage);
